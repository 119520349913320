function setBasicCarousel (elem, param) { //Owl Carousel properties
	var owl = elem.owlCarousel({
		loop:               param.loop,
		margin:             param.margin,
		rewind:             param.rewind,
		nav:                param.nav,
		touchDrag:          param.touchDrag,
		dots:               param.dots,
		animateIn:          param.animateIn,
		animateOut:         param.animateOut,
		mouseDrag:          param.mouseDrag,
		autoplay:           param.autoplay,
		autoplaySpeed:      param.autoplaySpeed,
		autoplayTimeout:    param.autoplayTimeout,
		autoplayHoverPause: param.autoplayHoverPause,
		responsive:         param.responsive,
	});
	if (param.lArrow) {
		param.lArrow.click(function () {
			owl.trigger('prev.owl.carousel', [300]);
		});
	}
	if (param.rArrow) {
		param.rArrow.click(function () {
			owl.trigger('next.owl.carousel');
		});
	}
	return owl;
}

function setCarousels () {
	//------- Main Carousel -------//
	var mainCarousel = setBasicCarousel($('#mainCarousel'), {
		autoplay:        false,
		autoplayTimeout: 5000,
		nav:             false,
		rewind:          true,
		dots:            true,
		responsive:      {
			0: {
				items: 1
			},
		}

	});
	//------- Best Seller Carousel -------//
	if ($('.owl-carousel').length > 0) {
		var bestSellerCarousel = setBasicCarousel($('#bestSellerCarousel'), {
			loop:       true,
			margin:     30,
			nav:        true,
			navNext:    ["<i class='ti-arrow-left'></i>", "<i class='ti-arrow-right'></i>"],
			dots:       false,
			responsive: {
				0:    {
					items: 1
				},
				767:  {
					items: 2
				},
				1000: {
					items: 3
				},
				1130: {
					items: 4
				}
			}
		});
	}

	//------- hero carousel -------//
	var heroCarousel = setBasicCarousel($('.hero-carousel'), {
		items:           3,
		margin:          10,
		autoplay:        false,
		autoplayTimeout: 5000,
		loop:            true,
		nav:             false,
		dots:            false,
		responsive:      {
			0:   {
				items: 1
			},
			600: {
				items: 2
			},
			810: {
				items: 3
			}
		}
	});
}

$(function () {
	"use strict";

	//------- Parallax -------//
	skrollr.init({
		forceHeight: false
	});

	//------- Active Nice Select --------//
	$('select').niceSelect();


	//------- fixed navbar --------//
	$(window).scroll(function () {
		var sticky = $('.header_area'),
			scroll = $(window).scrollTop();

		if (scroll >= 100) {
			sticky.addClass('fixed');
		} else {
			sticky.removeClass('fixed');
		}
	});
});


//---------Gallery modal --------//
function showModal () {
	$('.gallery-box a').click(function () {
		var src = $(this).attr('href');
		var title = $(this).parent().find('p').text();
		$('#videoModal').find('.modal-title').text(title);
		$('#videoModal').find('.modal-body').html(`<iframe frameborder="0" src="${src}" width="100%" height="400"></iframe>`);
	});
	$('#videoModal').on('hidden.bs.modal', function () {
		$('#videoModal').find('.modal-body').html('');
	})
}
//-------- Gallery album ---------//
$('.gallery-item a').simpleLightbox();


//------search------//
function search() {
	function destroySearch(){
		$('.search-field').removeClass('active');
		$('.search-field input').val('');
	}
	$('#searchBtn').click(function () {
		$('.search-field').addClass('active');
		$('.search-field input').focus();
	})
	$('.search-field .cls').click(destroySearch);
	$('.search-field input').focusout(destroySearch);

	$(document).keydown(function(e) {
		if (e.keyCode == 27) {
			destroySearch();
		}
	});
}
